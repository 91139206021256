// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("iconfont.woff2?t=1663380516425", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("iconfont.woff?t=1663380516425", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("iconfont.ttf?t=1663380516425", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n  font-family: \"iconfont\"; /* Project id 2974387 */\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),\r\n       url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('truetype');\r\n}\r\n\r\n.iconfont {\r\n  font-family: \"iconfont\" !important;\r\n  font-size: 16px;\r\n  font-style: normal;\r\n  -webkit-font-smoothing: antialiased;\r\n  -moz-osx-font-smoothing: grayscale;\r\n}\r\n\r\n.icon-weixuanzhongyuanquan:before {\r\n  content: \"\\e600\";\r\n}\r\n\r\n.icon-gouxuan:before {\r\n  content: \"\\e60f\";\r\n}\r\n\r\n.icon-tubiaozhizuomoban:before {\r\n  content: \"\\e63c\";\r\n}\r\n\r\n.icon-dingdan:before {\r\n  content: \"\\e63a\";\r\n}\r\n\r\n.icon-yifukuan:before {\r\n  content: \"\\e719\";\r\n}\r\n\r\n.icon-rili:before {\r\n  content: \"\\e8b4\";\r\n}\r\n\r\n.icon-dingdanwancheng:before {\r\n  content: \"\\e690\";\r\n}\r\n\r\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
