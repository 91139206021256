var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer-wrap clearfix" }, [
    _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "ftera" }, [
        _c("ul", [
          _c("li", { on: { click: _vm.toChat } }, [_vm._v("联系我们")]),
          _c("li", { on: { click: _vm.helpcenter } }, [_vm._v(" 帮助中心 ")]),
          _c("li", { on: { click: _vm.toAgrment } }, [_vm._v("隐私协议")]),
        ]),
        _vm._m(0),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("p", [_vm._v("官方客服+6620816688 +66889066661")])])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fterb" }, [
      _c("p", [
        _vm._v(
          "S.E.T. CULTURE & MEDIA CO.,LTD. | © Copyright © 2022-2023 S.E.T. CULTURE & MEDIA CO.,LTD.版权所有"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }