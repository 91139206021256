var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-wrap" },
    [
      _c("Topbu"),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("img", {
            staticClass: "pc",
            attrs: { src: require("../assets/imgs/banner.png"), alt: "" },
          }),
          _c("img", {
            staticClass: "phone",
            attrs: { src: _vm.phone, alt: "" },
          }),
          _vm._l(_vm.RegionList, function (item, index) {
            return _c(
              "router-link",
              {
                key: index,
                class: "item" + index,
                attrs: {
                  to: { path: "/IndexView", query: { activeName: item.value } },
                },
              },
              [_vm._v(_vm._s(item.label) + " ")]
            )
          }),
        ],
        2
      ),
      _c("Footer"),
      _vm.isClose
        ? _c("div", { staticClass: "qrcode" }, [
            _c(
              "div",
              {
                staticClass: "close",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.close },
              },
              [_vm._v("关闭")]
            ),
            _vm._m(0),
            _c("img", {
              staticStyle: { width: "100%", height: "100" },
              attrs: { src: require("../assets/imgs/qrcode.png"), alt: "" },
            }),
          ])
        : _vm._e(),
      !_vm.isClose
        ? _c(
            "div",
            {
              staticClass: "left",
              on: {
                click: function ($event) {
                  _vm.isClose = true
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../assets/icon/qrcodeicon.png"),
                  width: "50%",
                  height: "50%",
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [_vm._v("下载APP"), _c("br"), _vm._v("体验更多功能")])
  },
]
render._withStripped = true

export { render, staticRenderFns }