<!--
 * @Author: 'jxh' '1965452276@qq.com'
 * @Date: 2023-01-30 10:02:14
 * @LastEditors: 'jxh' '1965452276@qq.com'
 * @LastEditTime: 2023-02-02 14:38:59
 * @FilePath: \ly_pc3\src\components\footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="footer-wrap clearfix">
    <div class="footer">
      <div class="ftera">
        <ul>
          <li @click="toChat">联系我们</li>
          <li @click="helpcenter">
            <!-- <router-link to="/HelpCenter"></router-link> -->
            帮助中心
          </li>
          <li @click="toAgrment">隐私协议</li>
        </ul>
        <div>
          <p>官方客服+6620816688 +66889066661</p>
        </div>
      </div>
      <div class="fterb">
        <p>S.E.T. CULTURE & MEDIA CO.,LTD. | © Copyright © 2022-2023 S.E.T. CULTURE & MEDIA CO.,LTD.版权所有</p>
      </div>
    </div>
  </div>
</template>
<script>
import { registerIm } from '@/api/colllect';

export default {
  name: 'footer',
  methods: {
    helpcenter() {
      this.$router.push('/HelpCenter')
    },
    toAgrment() {
      this.$router.push('/PrivacyAgreement')
    },
    async toChat() {
      let token = this.$Cookies.get("token")
      if (!token) {
        return Message.error("未登录，请先登录。")
      }
      let res = await registerIm()
      if (res.data.code == 200) {
        //TODO 跳转到聊天页面
        let userInfo = JSON.parse(this.$Cookies.get("userInfo"));
        window.open("http://lvim.hbhnt.net" + "?userid=" + userInfo.id)
      } else {
        Message.error("系统错误，联系管理员")
      }
    },
  }
}
</script>
<style lang="scss">
@import "./top";

.footer-wrap {
  width: 100vw;
  height: 180px;
  background: #f7f8fa;
  /* position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%); */
}

.cross-title {
  height: 42px;
  line-height: 42px;
  width: 375px;
  position: relative;
  box-sizing: border-box;
  color: #fff;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  padding-left: 8px;
  background-image: linear-gradient(to right, #1b376e, #6c748b);

  &::before {
    position: absolute;
    content: "";
    border: 21px solid;
    border-color: transparent #1b376e transparent transparent;
    left: -42px;
  }
}
</style>
