<template>
  <div class="boxtop">
    <div class="top">
      <div class="logo">
        <img @click="$router.push('/')" src="../assets/imgs/bangzu/logo-2.jpg" alt="" />
      </div>
      <ul>
        <li>
          <router-link to="/IndexView"> 首页 </router-link>
        </li>
        <li>
          <router-link to="/ItineraryCustomization"> 行程定制 </router-link>
        </li>
        <li>
          <router-link to="/CommunityRecords"> 社区 </router-link>
        </li>
        <li>
          <router-link to="/AboutUs"> 关于我们 </router-link>
        </li>
        <li class="header-input">
          <el-input v-model.trim="keyword" placeholder="请输入关键字">
            <template slot="append"> <span @click="onSearch">搜索</span> </template>
          </el-input>
        </li>
      </ul>
      <div></div>
      <div class="avart">
        <img src="../assets/imgs/头像.png" style="width: 35px;height: 35px;" alt="" @click="openMuen = !openMuen">
        <div class="muen" v-if="openMuen">
          <div @click="toChat">客服</div>
          <div @click="gerenzhong" v-if="$Cookies.get('token')">个人中心</div>
          <div @click="toLogin" v-if="!$Cookies.get('token')">登录</div>
          <div @click="tuichu" v-else>退出</div>
        </div>
      </div>
      <div class="login">
        <a @click="toChat">客服</a>
        <router-link to="/Login" style="color: #86909c" v-if="!$Cookies.get('token')">登录</router-link>
        <div class="geren" v-else>
          <span class="name dot3 inblock" @click="gerenzhong">
            {{ userInfo && userInfo.nickName }},
          </span>
          <span class="inblock logoutbtn" @click="tuichu">退出</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { Message } from 'element-ui';
import { registerIm } from '@/api/colllect';
export default {
  data() {
    return {
      dialogVisible: false,
      userInfo: null,
      keyword: "",
      openMuen: false
    };
  },
  mounted() {

    const info = Cookies.get("userInfo");
    if (info) {
      this.userInfo = JSON.parse(info);
      console.log("--header右边-userInfo--", this.userInfo);
      // this.$Cookies.set(
      //   "token",
      //   "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjhmM2NmYzRhLTIwNjQtNDY5NC1hNmMwLTNlNTExYThjMmYzOCJ9.zeMfPrWZ4_KJ3E_weTCW2U0R3hP1HwzAN1hZKZVL0sQKJpl6hKsuD4hPvst7UUNz50IXoA-jE32gJ--c06BY-A",
      //   { expires: 7 }
      // );
    }
  },
  methods: {
    toLogin() {
      this.$router.push('/Login')
    },
    tuichu() {
      this.$Cookies.remove("token");
      this.$router.push("./"); // 退出登录后返回首页
      location.reload(); //刷新页面
    },
    // async toChat() {
    //   let token = this.$Cookies.get("token")
    //   if (!token) {
    //     return Message.error("未登录，请先登录。")
    //   }
    //   let res = await registerIm()
    //   if (res.data.code == 200) {
    //     //TODO 跳转到聊天页面
    //     let userInfo = JSON.parse(this.$Cookies.get("userInfo"));
    //     window.open("http://lvim.hbhnt.net" + "?userid=" + userInfo.id)
    //   } else {
    //     Message.error("系统错误，联系管理员")
    //   }
    // },
    async toChat() {
      let token = Cookies.get("token")
      if (!token) {
        return Message.error("未登录，请先登录。")
      }
      let res = await registerIm()
      if (res.data.code == 200) {
        //TODO 跳转到聊天页面
        let userInfo = JSON.parse(Cookies.get("userInfo"));
        window.open("http://live.setgo.vip:3006" + "?userid=" + userInfo.id)
      } else {
        Message.error("系统错误，联系管理员")
      }
    },
    onSearch() {
      if (!this.keyword) {
        this.$message({
          type: "info",
          message: "请输入关键字",
        });
      } else {
        this.$router.push({
          path: "/searchInfo",
          query: {
            name: this.keyword
          }
        });
      }
    },
    handleClose(done) {
      this.dialogVisible = false;
    },
    // 退出登录
    tuichu() {
      this.$Cookies.remove("token");
      this.$router.push("./"); // 退出登录后返回首页
      location.reload(); //刷新页面
    },
    // 跳转个人中心
    gerenzhong() {
      this.$router.push({
        path: "/PersonalCenter",
      });
      // alert("44444444")
      // location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./top";

.name {
  max-width: 140px;
}

.logoutbtn,
.name {
  vertical-align: middle;
}

.geren {
  color: #86909c;
  font-size: 14px;
}
</style>
