<template>
  <div class="home-wrap">
    <Topbu></Topbu>
    <div class="content">

      <img class="pc" src="../assets/imgs/banner.png" alt="" />
      <img class="phone" :src="phone" alt="" />
      <router-link v-for="(item, index) in RegionList" :key="index" :class="'item' + index"
        :to="{ path: '/IndexView', query: { activeName: item.value } }">{{ item.label }}
      </router-link>
      <!-- <router-link
        :to="{path:'/IndexView',query: {activeName: 'one'}}"
        class="mangu"
      >曼谷
      </router-link>
      <router-link
        :to="{path:'/IndexView',query: {activeName:'two'}}"
        class="batiya"
      >芭提雅
      </router-link>
      <router-link
        :to="{path:'/IndexView',query: {activeName:'three' }}"
        class="puji"
      >普吉
      </router-link> -->
    </div>
    <Footer></Footer>
    <div v-if="isClose" class="qrcode">
      <div class="close" @click="close" style="cursor: pointer;">关闭</div>
      <p>下载APP<br />体验更多功能</p>
      <img style="width: 100%;height: 100;" src="../assets/imgs/qrcode.png" alt="" />
    </div>
    <div class="left" v-if="!isClose" @click="isClose = true">
      <img src="../assets/icon/qrcodeicon.png" width="50%" height="50%" alt="">
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Cookies from "js-cookie";
import Topbu from "../components/topbu.vue";
import Footer from "../components/footer.vue";
import { getRegion } from "../api/colllect.js";
export default {
  name: "HomeView",
  components: {
    Topbu,
    Footer,
  },
  data() {
    return {
      isClose: true,
      activeName: "four",
      RegionList: [],
      userInfo: {},
      phone: require('../assets/imgs/phone.png')
    };
  },
  created() {
    window.navigator.get
  },
  async mounted() {
    const result = await getRegion();
    if (result && result.code != 200) return;
    this.RegionList = result.data;
    console.log("this.RegionList", this.RegionList);
  },
  methods: {

    close() {
      this.isClose = false
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../components/top.scss";

.left {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(101, 184, 254);
  transform: translateX(40%);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.content {
  width: 100%;
  height: 900px;
  position: relative;

  a {
    opacity: 0;
  }

  .item0 {
    width: 200px;
    height: 123px;
    // background: red;
    position: absolute;
    left: 50%;
    top: 289px;
    margin-left: -168px;
    text-align: center;
    padding-top: 50px;
    font-size: 30px;
    color: #000;
  }

  .item1 {
    width: 200px;
    height: 123px;
    // background: green;
    position: absolute;
    left: 50%;
    top: 414px;
    margin-left: -142px;
    text-align: center;
    padding-top: 50px;
    font-size: 30px;
    color: #000;
    // border: 2px solid red;
  }

  .item3 {
    width: 200px;
    height: 200px;
    position: absolute;
    // background: yellow;
    left: 50%;
    top: 50px;
    margin-left: -212px;
    text-align: center;
    padding-top: 100px;
    font-size: 30px;
    color: #000;
    // border: 2px solid red;
  }

  .item2 {
    width: 223px;
    height: 243px;
    // background: hotpink;
    position: absolute;
    left: 50%;
    top: 628px;
    margin-left: -172px;
    text-align: center;
    padding-top: 100px;
    font-size: 30px;
    color: #000;
    // border: 2px solid red;
  }
}

.content>img {
  height: 100%;
  position: absolute;
  left: 50%;
  margin-left: -970px;
}

body {
  position: relative;
}

.qrcode {
  width: 190px;
  height: 258px;
  background: #ffffff;
  position: fixed;
  right: 90px;
  top: 276px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #f2f3f5;
  z-index: 9999;
}

.qrcode p {
  margin-top: 30px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #000000;
  line-height: 24px;
}

@media (min-width: 454px) and (max-width: 9999px) {
  .phone {
    display: none
  }

  .pc {
    display: block
  }
}

@media (min-width: 1px) and (max-width: 455px) {
  .phone {
    // display: block
    position: initial !important;
    margin-left: 0 !important;
  }

  .pc {
    width: 0;
    height: 0;
  }
}
</style>
