<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { registerIm } from '@/api/colllect';
import { Message } from 'element-ui';
export default {
  provide() {
    // 父组件中返回要传给下级的数据
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      openMuen: false,
    };
  },
  methods: {

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      })
    },
  },
};
</script>

<style lang="scss">
.avart {
  display: none;
}

@media (max-width: 480px) {


  .avart {
    display: block;
    width: 40px;
    height: 40px;
    position: fixed;
    top: 5px;
    right: 00px;

    img {
      width: 40px;
      height: 40px;
    }

    .muen {
      position: absolute;
      z-index: 999;
      background-color: #fff;
      width: 80px;
      bottom: 0;
      border-radius: 4px;
      font-size: 14px;
      transform: translate(-50%, 110%);
      box-sizing: border-box;
      padding: 5px;
    }
  }
}

body {}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}

.el-tabs__item {
  color: #818080;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#app /deep/ .el-radio__input.is-checked .el-radio__inner::after {
  content: "";
  width: 8px;
  height: 3px;
  border: 2px solid white;
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 2px;
  left: 1px;
  vertical-align: middle;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
}
</style>
