import axios from 'axios'
import qs from 'qs'
import Cookies from 'js-cookie'
import { Message } from 'element-ui';

import {
  API_URL
} from "../../env"

let response = axios.create({
  // baseURL: '/api',
  timeout: 5000
})



// 请求拦截器
axios.interceptors.request.use(
  config => {
    //  token && (config.headers.Authorization = token)
    const token = Cookies.get('token');
    if (token) {
      // alert('2')
      config.headers.Authorization = token
    }
    console.log(token)
    return config
  },
  error => {
    return Promise.error(error)
  })
//你动吧



response.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
// 响应拦截器
response.interceptors.response.use(response => {
  if (response.status === 200) {
    return Promise.resolve(response)
  } else {
    return Promise.reject(response)
  }
}, error => {
  if (error.response.status) {
    // 对不同返回码对相应处理
    return Promise.reject(error.response)
  }
})


export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    response({
      url: API_URL + url,
      method: 'get',
      params: params,
      headers:{
        Authorization:Cookies.get('token')
      }
      
    }).then(res => {
      if (res && res.data && res.data.code === 200) {
        resolve(res);
      } else if (res && res.data && res.data.code === 500) {
        Message.error('系统出错')
      } else if (res && res.data && res.data.code === 502) {
        Message.error('连接超时')
      } else if (res && res.data && res.data.code === 401) {
        Message.error('登录失效')
        // location.href = '/login';
      } else {
        Message.error(res?.data?.msg || '操作失败')
        // // 通过配置可关闭错误提示
        // if (options.error) console.error(res.message);
        // reject(res);
      }
      // resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

// qs.stringify(data),
export function post(url, data = {}, out) {
  return new Promise((resolve, reject) => {
    // console.log(qs.stringify(data), "111111111"),
    axios.post(API_URL + url, qs.parse(data),
    ).then(
      response => {
        resolve(response.data);
      },
      err => {
        reject(err);
      }
    );
  });
}
// headers: {
//   "Content-Type": "application/json;charset=utf-8"
// }


export default {
  get,
  post
}