export default {
    checkCode: '/app/QRCode/check',
    getUserInfo: '/app/getCustomer',
    noticeList: '/app/notice/list',
    noticeDetail: '/app/notice/profile',
    // 关于我们
    aboutUs: '/app/aboutUs',
    //帮助中心
    helpCenter: '/app/helpInfo/list'

}
