import { get, post } from '../utils/request';


// 手机号验证码请求
export function getVerificationCode(data) {
  return get(`/app/authentication`, data);

}
// 隐私协议
export function getAgreement(data) {
  return get(`/app/aboutUs?id=2`);

}
export function getQRCode(data) {
  return get(`/app/QRCode`);

}
export function checkQrLogin(data) {
  return get(`/app/QRCode/check/${data}`);
}

//注册im
export function registerIm(data) {
  return get(`/app/registerIm`, data);
}

// 登录请求
export function getLogin(data) {
  return post('/app/phoneLogin', data);

}
// 获取用户信息
export function getCustomer(data) {
  return post('/app/getCustomer', data);

}

// 点击获取区域
export function getRegion(data) {
  return post('/app/getRegion', data);

}
// 点击获取项目类型
export function getType(data) {
  return post('/app/getType', data);

}
// 点击获取项目列表
export function getProducts(data) {
  return post('/app/getProducts', data);

}
// 点击点赞
export function getZanProducts(data) {
  return post('/app/zanProducts', data);

}
// 取消点赞
export function getDelZanProducts(data) {
  return post('/app/delZanProducts', data);

}
// 点击添加心愿单
export function getCollectionProducts(data) {
  return post('/app/collectionProducts', data);

}
// 取消心愿单
export function getDelCollection(data) {
  return post('/app/delCollectionProducts', data);

}
// 景点详情
export function getProductsById(data) {
  return post('/app/getProductsById', data);

}
// 分享景点
export function getShareProducts(data) {
  return post('/app/shareProducts', data);

}
// 社区动态类型
export function getDynamicType(data) {
  return post('/app/getDynamicType', data);

}
// 社区游记列表
export function getDynamicList(data) {
  return post('/app/dynamicList', data);

}
// 添加社区动态
export function getAddDynamic(data) {
  return post('/app/addDynamic', data);

}
// 文件上传
export function getAddUpload(data) {
  return post('/app/upload', data);

}
// 社区动态详情
export function getDynamicById(data) {
  return post('/app/dynamicById', data);

}
// 社区动态添加评论
export function getCommentZanDynamic(data) {
  return post('/app/commentZanDynamic', data);

}
// 社区动态全部评论
export function getDynamicComment(data) {
  return post('/app/dynamicComment', data);

}
// 社区动态评论删除
export function getDelDynamicComment(data) {
  return post('/app/delDynamicComment', data);
}
// 点赞动态评论 superiorId
export function likeComment(data) {
  return post('/app/zanComment', data);
}
/// 取消点赞动态评论
export function cancellikeComment(data) {
  return post('/app/delZanComment', data);
}


////  个人中心 ////

// 我的订单列表
export function getOrderList(data) {
  return post('/app/getOrderList', data);

}
// 我的订单列表
export function getOrderDetails(data) {
  return post('/app/orderDetails', data);

}
// 订单评价
export function getAppraise(data) {
  return post('/app/appraise', data);

}
// 修改个人信息
export function getEditCustomer(data) {
  return post('/app/editCustomer', data);

}
// 我的评价列表
export function getAppraiseList(data) {
  return post('/app/appraiseListByCustomer', data);

}
// 我的心愿单
export function getWishList(data) {
  return post('/app/getWishList', data);

}
// 我的主页列表
export function getDynamicListByCustomer(data) {
  return post('/app/dynamicListByCustomer', data);

}
// 取消点赞社区动态
export function getDelZanDynamic(data) {
  return post('/app/delZanDynamic', data);

}
// 点赞社区动态
export function getzanDynamic(data) {
  return post('/app/zanDynamic', data);

}
// 行程定制
export function getAddTravel(data) {
  return post('/app/addTravel', data);
}

// 行程定制2
export function sendCustomization(data) {
  return post('/im/sendCustomization', data);
}




