var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "boxtop" }, [
    _c("div", { staticClass: "top" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { src: require("../assets/imgs/bangzu/logo-2.jpg"), alt: "" },
          on: {
            click: function ($event) {
              return _vm.$router.push("/")
            },
          },
        }),
      ]),
      _c("ul", [
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/IndexView" } }, [
              _vm._v(" 首页 "),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/ItineraryCustomization" } }, [
              _vm._v(" 行程定制 "),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/CommunityRecords" } }, [
              _vm._v(" 社区 "),
            ]),
          ],
          1
        ),
        _c(
          "li",
          [
            _c("router-link", { attrs: { to: "/AboutUs" } }, [
              _vm._v(" 关于我们 "),
            ]),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "header-input" },
          [
            _c(
              "el-input",
              {
                attrs: { placeholder: "请输入关键字" },
                model: {
                  value: _vm.keyword,
                  callback: function ($$v) {
                    _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "keyword",
                },
              },
              [
                _c("template", { slot: "append" }, [
                  _c("span", { on: { click: _vm.onSearch } }, [_vm._v("搜索")]),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("div"),
      _c("div", { staticClass: "avart" }, [
        _c("img", {
          staticStyle: { width: "35px", height: "35px" },
          attrs: { src: require("../assets/imgs/头像.png"), alt: "" },
          on: {
            click: function ($event) {
              _vm.openMuen = !_vm.openMuen
            },
          },
        }),
        _vm.openMuen
          ? _c("div", { staticClass: "muen" }, [
              _c("div", { on: { click: _vm.toChat } }, [_vm._v("客服")]),
              _vm.$Cookies.get("token")
                ? _c("div", { on: { click: _vm.gerenzhong } }, [
                    _vm._v("个人中心"),
                  ])
                : _vm._e(),
              !_vm.$Cookies.get("token")
                ? _c("div", { on: { click: _vm.toLogin } }, [_vm._v("登录")])
                : _c("div", { on: { click: _vm.tuichu } }, [_vm._v("退出")]),
            ])
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "login" },
        [
          _c("a", { on: { click: _vm.toChat } }, [_vm._v("客服")]),
          !_vm.$Cookies.get("token")
            ? _c(
                "router-link",
                { staticStyle: { color: "#86909c" }, attrs: { to: "/Login" } },
                [_vm._v("登录")]
              )
            : _c("div", { staticClass: "geren" }, [
                _c(
                  "span",
                  {
                    staticClass: "name dot3 inblock",
                    on: { click: _vm.gerenzhong },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.userInfo && _vm.userInfo.nickName) + ", "
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "inblock logoutbtn",
                    on: { click: _vm.tuichu },
                  },
                  [_vm._v("退出")]
                ),
              ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }