import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/PrivacyAgreement',
    name: 'PrivacyAgreement',
    component: () => import('@/views/PrivacyAgreement.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/tim',
    name: 'tim',
    component: () => import('../views/tim.vue')
  },
  {
    path: '/IndexView',
    name: 'IndexView',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/ItineraryCustomization',
    name: 'ItineraryCustomization',
    component: () => import('../views/ItineraryCustomization.vue')
  },
  {
    path: '/OnlineService',
    name: 'OnlineService',
    component: () => import('../components/OnlineService.vue')
  },
  {
    path: '/CommunityRecords',
    name: 'CommunityRecords',
    component: () => import('../views/CommunityRecords.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import('../views/AboutUs.vue')
  },
  {
    path: '/ArticleDetails',
    name: 'ArticleDetails',
    component: () => import('../components/ArticleDetails.vue')
  },
  {
    path: '/FeatureAll',
    name: 'FeatureAll',
    component: () => import('../views/FeatureAll.vue')
  },
  {
    path: '/AttractionDetails',
    name: 'AttractionDetails',
    component: () => import('../views/AttractionDetails.vue')
  },
  {
    path: '/HelpCenter',
    name: 'HelpCenter',
    component: () => import('../views/HelpCenter.vue')
  },
  {
    path: '/PersonalCenter',
    name: 'PersonalCenter',
    component: () => import('../views/PersonalCenter.vue')
  },
  {
    path: '/PersonalCenter/Myzhuye',
    name: 'Myzhuye',
    component: () => import('../components/gerenhzongx/Myzhuye.vue')
  },
  {
    path: '/PersonalCenter/OrderDetails',
    name: 'OrderDetails',
    component: () => import('../components/gerenhzongx/OrderDetails.vue')
  },
  {
    path: '/PersonalCenter/SettleMent',
    name: 'SettleMent',
    component: () => import('../components/gerenhzongx/SettleMent.vue')
  },
  {
    path: '/PersonalCenter/ScancodeTopay',
    name: 'ScancodeTopay',
    component: () => import('../components/gerenhzongx/ScancodeTopay.vue')
  },
  {
    path: '/PersonalCenter/Myzhuye/ReleaseYouji',
    name: 'ReleaseYouji',
    component: () => import('../components/gerenhzongx/ReleaseYouji.vue')
  },
  {
    path: '/PersonalCenter/ProductReview',
    name: 'ProductReview',
    component: () => import('../components/gerenhzongx/ProductReview.vue')
  },
  {
    path: '/PersonalCenter/Aspiration',
    name: 'Aspiration',
    component: () => import('../components/gerenhzongx/Aspiration.vue')
  },
  {
    path: '/searchInfo',
    name: 'searchInfo',
    component: () => import('../views/searchInfo.vue')
  },


]

const router = new VueRouter({
  routes
})

export default router
