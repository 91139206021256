
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.css';
import './assets/css/common.css';

import { BASE_URL } from '../env'

import axios from 'axios';
import qs from 'qs'
import Cookies from 'js-cookie'


import request from './utils/myrequest'
Vue.prototype.request = request;
import apis from "./utils/apiUrl"

// import "./utils/rem"

Vue.prototype.$apis = apis;


import {
  message
} from '@/utils/message.js';
Vue.prototype.$message = message;


Vue.prototype.$Cookies = Cookies;
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;

Vue.prototype.$BASE_URL = BASE_URL;



import 'element-ui/lib/theme-chalk/display.css';

Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')





